import { Children, cloneElement, isValidElement, useMemo } from 'react'
import { KeenSliderOptions, useKeenSlider } from 'keen-slider/react'
import { CarouselVariantProps, carousel } from './carousel.variants'

export type useCarouselProps = CarouselVariantProps & {
  className?: string
  children?: React.ReactNode
  opts?: KeenSliderOptions
}

export const useCarousel = (props: useCarouselProps) => {
  const { className = '', children, opts } = props

  const [ref] = useKeenSlider<HTMLDivElement>(opts, [
    (slider) => {
      if (opts?.loop && Children.count(children) > 1) {
        let timeout: any
        let mouseOver = false

        const clearNextTimeout = () => {
          clearTimeout(timeout)
        }

        const nextTimeout = () => {
          clearTimeout(timeout)
          if (mouseOver) return
          timeout = setTimeout(() => {
            slider.next()
          }, 2000)
        }

        slider.on('created', () => {
          slider.container.addEventListener('mouseover', () => {
            mouseOver = true
            clearNextTimeout()
          })
          slider.container.addEventListener('mouseout', () => {
            mouseOver = false
            nextTimeout()
          })
          nextTimeout()
        })

        slider.on('dragStarted', clearNextTimeout)
        slider.on('animationEnded', nextTimeout)
        slider.on('updated', nextTimeout)
      }
    }
  ])

  const styles = useMemo(
    () => carousel({ className: `${className} w-full keen-slider` }),
    [className]
  )

  const slides = Children.map(children, (child) => {
    if (!isValidElement<{ className?: string }>(child)) return child

    const existingClassName = child.props.className || ''

    const newClassName =
      `${existingClassName} keen-slider__slide !overflow-visible`.trim()

    return cloneElement(child, { className: newClassName })
  })

  return {
    ref,
    styles,
    slides
  }
}
