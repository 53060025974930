'use client'

import { useCarousel, useCarouselProps } from './use-carousel'

export type CarouselProps = useCarouselProps

const Carousel = (props: useCarouselProps) => {
  const { ref, styles, slides } = useCarousel(props)

  return (
    <div ref={ref} className={styles}>
      {slides}
    </div>
  )
}

export default Carousel
